export var dados = [
  ['../assets/conhecimentos/django.png', "Django", "../assets/estrelas/4.png", ['https://ude.my/UC-ddd6c4de-c6bc-42ac-9b8b-a26b5f8afc9c'], [['Site de Imobiliária', 'http://www.mydjango.kinghost.net/curso_django/'], ['Sistema de Consultas', 'http://www.mydjango.kinghost.net/sistema_de_consultas/']]],
  ['../assets/conhecimentos/express.webp', "Express", "../assets/estrelas/4.png", ['https://ude.my/UC-c85e64cd-3660-46d8-a1ae-03657f5741a4'], [['jogBola', 'http://www.jogbola.com/']]],
  ['../assets/conhecimentos/figma.png', "Figma", "../assets/estrelas/5.png", ['https://www.udemy.com/certificate/UC-d01d6706-9d7a-43eb-85cd-257087322a59/'], [['Portfólio', 'https://caioagralemos.com/'], ['Espaço Gouvêa', 'https://espacogouvea.com.br/'], ['RevisAi', 'https://revisai.co/en'], ['Potoca', 'https://bit.ly/playpotoca'], ['rtn', 'https://usertn.com'], ['Bill', 'https://bit.ly/cal-bill'], ['Tabooada', 'https://bit.ly/cal-tabooada'], ["Climate Stories", "https://bit.ly/climatestoriescal"]]],
  ['../assets/conhecimentos/firebase.webp', "Firebase", "../assets/estrelas/5.png", [], [['RevisAi', 'https://revisai.co/en'], ]],
  ['../assets/conhecimentos/java.png', "Java", "../assets/estrelas/4.png", ['https://i.imgur.com/rLUBMvE.jpeg'], [['Página', 'https://github.com/caioagralemos/Pagina'], ['Clinicheck', 'https://github.com/caioagralemos/CliniCheck']]],
  ['../assets/conhecimentos/js.png', "JavaScript", "../assets/estrelas/5.png", ['https://www.udemy.com/certificate/UC-c85e64cd-3660-46d8-a1ae-03657f5741a4/', 'https://www.udemy.com/certificate/UC-d0071c05-4198-4f49-ac05-1eefe1f648ad/', 'https://imgur.com/8NlKlmr'], [['Escritório de Arquitetura', 'https://agralemos.com.br/'], ['Espaço Gouvêa', 'https://espacogouvea.com.br/'], ['jogBola', 'http://www.jogbola.com/'], ['Portfólio', 'https://caioagralemos.com/']]],
  ['../assets/conhecimentos/mongodb.webp', "MongoDB", "../assets/estrelas/4.png", ['https://ude.my/UC-c85e64cd-3660-46d8-a1ae-03657f5741a4'], [['jogBola', 'http://www.jogbola.com/']]],
  ['../assets/conhecimentos/mysql.png', "MySQL", "../assets/estrelas/5.png", ['https://www.udemy.com/certificate/UC-f1d4a184-c7c8-4981-9343-375e602e2323/'], [['Projeto', 'https://github.com/caioagralemos/UFAL/tree/main/BD%202023.1/Projeto']]],
  ['../assets/conhecimentos/next.png', "NextJS", "../assets/estrelas/5.png", ['https://www.udemy.com/certificate/UC-91b922aa-92e7-46e4-b612-ea281047a8b4/'], [['Cassino', 'https://github.com/caioagralemos/Cassino-NextJS'], ['Blog', 'https://github.com/caioagralemos/Blog-Next.js']]],
  ['../assets/conhecimentos/postgres.png', "Postgres", "../assets/estrelas/4.png", ['https://ude.my/UC-ddd6c4de-c6bc-42ac-9b8b-a26b5f8afc9c'], [['Site de Imobiliária', 'http://www.mydjango.kinghost.net/curso_django/'], ['Sistema de Consultas', 'http://www.mydjango.kinghost.net/sistema_de_consultas/']]],
  ['../assets/conhecimentos/py.png', "Python", "../assets/estrelas/5.png", ['https://i.imgur.com/vlmUXtX.png', 'https://www.udemy.com/certificate/UC-565289ea-f237-4b6f-9f92-107df9acc1b6/', 'https://www.udemy.com/certificate/UC-e6354290-d1b1-4774-a051-03fe0455121a/'], [['QuizGame', 'https://github.com/caioagralemos/Projetos-Python/tree/main/QuizGame'], ['WeatherApp', 'https://github.com/caioagralemos/Projetos-Python/tree/main/WeatherApp%20PTBR']]],
  ['../assets/conhecimentos/react.webp', "React", "../assets/estrelas/5.png", ['https://www.udemy.com/certificate/UC-45cabd66-625a-4d33-a514-02236d6ef5e1/', 'https://www.udemy.com/certificate/UC-91b922aa-92e7-46e4-b612-ea281047a8b4/'], [['RevisAi', 'https://revisai.co/en'], ['Portfolio', 'https://caioagralemos.com/'], ['Espaço Gouvêa', 'https://espacogouvea.com.br/'], ]],
  ['../assets/conhecimentos/sass.webp', "SASS", "../assets/estrelas/4.png", ['https://ude.my/UC-c85e64cd-3660-46d8-a1ae-03657f5741a4', 'https://imgur.com/8NlKlmr'], [['Portfolio', 'https://caioagralemos.com/'], ['Espaço Gouvêa', 'https://espacogouvea.com.br/'], ['RevisAi', 'https://revisai.co/en']]],
  ['../assets/conhecimentos/swift.webp', "Swift", "../assets/estrelas/5.png", ['https://i.imgur.com/wQFDcUY.png', 'https://i.imgur.com/xwxRoHn.png', 'https://www.udemy.com/certificate/UC-76a6c0c7-1399-48e2-bb7f-1ed503eceb01/'], [['Potoca', 'https://bit.ly/playpotoca'], ['rtn', 'https://usertn.com'], ['Bill', 'https://bit.ly/cal-bill'], ['Tabooada', 'https://bit.ly/cal-tabooada'], ["Climate Stories", "https://bit.ly/climatestoriescal"], ["Retrato", "https://bit.ly/retratoytb"]]],
  ['../assets/conhecimentos/swiftdata.webp', "SwiftData", "../assets/estrelas/3.png", ['https://www.udemy.com/certificate/UC-76a6c0c7-1399-48e2-bb7f-1ed503eceb01/'], [['rtn', 'https://usertn.com'],]],
  ];